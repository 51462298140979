import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { IconButton, InputAdornment, Switch, FormControlLabel, Select, InputLabel, MenuItem, Input } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const ChatbotsSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	 queueId: Yup.array().of(Yup.string()),
	greetingMessage: Yup.string(),
	 chatbotEnabled: Yup.boolean(), 
	});

const BotModal = ({ open, onClose, botId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		greetingMessage: "",
		chatbotEnabled: 0, // Nuevo campo para activar/desactivar el chatbot
		 queueId: [],
	};

	const [bots, setBots] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const greetingRef = useRef();

	 useEffect(() => {
    (async () => {
      if (!botId) return;
      try {
        const { data } = await api.get(`/bots/${botId}`);
        setBots((prevState) => {
          return { ...prevState, ...data };
        });
				console.log(data);
			const userBotsIds = data.queues?.map(queue => queue.id) || [];
				console.log(userBotsIds);
				setSelectedQueueIds(userBotsIds);
        //const userBotsIds = data.bots?.map((bots) => bots.id);
        //setSelectedQueueIds(userBotsIds);
      } catch (err) {
        toastError(err);
      }
    })();

		return () => {
     	 setBots(initialState);
    };
  }, [botId, open]);

	const handleClose = () => {
		onClose();
		setBots(initialState);
	};

const handleSaveBots = async values => {
  const updatedValues = {
    ...values,
    queueId: selectedQueueIds,
    chatbotEnabled: values.chatbotEnabled ? 1 : 0,
  };
  try {
    console.log(botId);
    if (botId) {
      console.log(updatedValues);
      await api.put(`/bots/${botId}`, updatedValues);
    } else {
      await api.post("/bots", updatedValues);
    }
    toast.success(i18n.t("botModal.success"));
  } catch (err) {
    console.error("API PUT Request Error:", err);
    toastError(err);
  }
  handleClose();
};


  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
				<DialogTitle>
					{botId
						? `${i18n.t("botModal.title.edit")}`
						: `${i18n.t("botModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={bots}
					enableReinitialize={true}
				validationSchema={ChatbotsSchema}
					onSubmit={(values, actions) => {
						  console.log("Formik onSubmit start");
						setTimeout(() => {
							handleSaveBots(values);
							actions.setSubmitting(false);
							console.log("Formik onSubmit end");
						}, 400);
					}
				}
				>
					{({ touched, errors, isSubmitting, values, setFieldValue }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("botModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<div>
									<Field
										as={TextField}
										label={i18n.t("botModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										inputRef={greetingRef}
										rows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="chatbotEnabled"
												checked={values.chatbotEnabled || 0}
											/>
										}
										label={i18n.t("botModal.form.chatbotEnabled")}
									/>
								<Field
          				        as={QueueSelect}
            							        selectedQueueIds={selectedQueueIds}
										          	onChange={values => setSelectedQueueIds(values)}
             														   />
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("botModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{botId
										? `${i18n.t("botModal.buttons.okEdit")}`
										: `${i18n.t("botModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default BotModal;
