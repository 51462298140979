import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { IconButton, InputAdornment, Switch, FormControlLabel, Select, InputLabel, MenuItem, Input } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import QueueSelect from "../QueueSelect";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const ChatbotsSchema = Yup.object().shape({
  queries: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  replyMessage: Yup.string()
    .required("Required"),
  requeries: Yup.string()
    .required("Required"),
  isAgent: Yup.string(),
  botsId: Yup.string(),
  isImg: Yup.string(),
  imgName: Yup.string(),    
});

const BotModal = ({ open, onClose, botId, id }) => {
  const classes = useStyles();

  const initialState = {
    queries: "",
    replyMessage: "",
    requeries: "",
    isAgent: "",
    isImg: "",
    imgName: "",
  };

  const [bots, setBots] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [isImgSelected, setIsImgSelected] = useState(false);

  useEffect(() => {
    (async () => {
      if (!botId) return;
      try {
        const { data } = await api.get(`/botsquerry/${botId}`);
        setBots((prevState) => {
          return { ...prevState, ...data };
        });
        const userBotsIds = data.queues?.map(queue => queue.id) || [];
        setSelectedQueueIds(userBotsIds);
      } catch (err) {
        toastError(err);
      }
    })();
    return () => {
      setBots(initialState);
    };
  }, [botId, open]);

  const handleClose = () => {
    onClose();
    setBots(initialState);
  };

  const handleSaveBots = async values => {
    const updatedValues = {
      ...values,
      botsId: id,
    };
    try {
      if (botId) {
        await api.put(`/botsquerry/${botId}`, updatedValues);
      } else {
        await api.post("/botsquerry", updatedValues);
      }
      toast.success(i18n.t("botModal.success"));
    } catch (err) {
      console.error("API PUT Request Error:", err);
      toastError(err);
    }
    handleClose();
  };

const handleIsImgChange = (event) => {
  const value = event.target.value;
  setIsImgSelected(value === "1");
  setBots(prevState => ({
    ...prevState,
    isImg: value
  }));
};
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
        <DialogTitle>
          {botId
            ? `${i18n.t("botQueriesModal.title.edit")}`
            : `${i18n.t("botQueriesModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={bots}
          enableReinitialize={true}
          validationSchema={ChatbotsSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveBots(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("botQueriesModal.form.proyc")}
                  autoFocus
                  name="botsId"
                  error={touched.proyc && Boolean(errors.proyc)}
                  helperText={touched.proyc && errors.proyc}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  value={id}
                  readonly
                />
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("botQueriesModal.form.queries")}
                    type="greetingMessage"
                    multiline
                    rows={5}
                    fullWidth
                    name="queries"
                    error={touched.queries && Boolean(errors.queries)}
                    helperText={touched.queries && errors.queries}
                    variant="outlined"
                    margin="dense"
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("botQueriesModal.form.replies")}
                    type="greetingMessage"
                    multiline
                    rows={5}
                    fullWidth
                    name="replyMessage"
                    error={touched.requierir && Boolean(errors.replies)}
                    helperText={touched.replies && errors.replies}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel id="profile-selection-input-label">
                      {i18n.t("botQueriesModal.form.requierir")}
                    </InputLabel>
                    <Field
                      as={Select}
                      label={i18n.t("botQueriesModal.form.requierir")}
                      name="requeries"
                      labelId="profile-selection-label"
                      id="requeries"
                      required
                    >
                      <MenuItem value={''}>&nbsp;</MenuItem>
                      <MenuItem value="cid">Cid</MenuItem>
                      <MenuItem value="none">Ninguno</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel id="profile-selection-input-label">
                      {i18n.t("botQueriesModal.form.isAgent")}
                    </InputLabel>
                    <Field
                      as={Select}
                      label={i18n.t("botQueriesModal.form.isAgent")}
                      name="isAgent"
                      labelId="profile-selection-label"
                      id="isAgent"
                      required
                    >
                      <MenuItem value="0">On</MenuItem>
                      <MenuItem value="1">Off</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel id="profile-selection-input-label">
                      {i18n.t("botQueriesModal.form.isImg")}
                    </InputLabel>
                    <Field
                      as={Select}
                      label={i18n.t("botQueriesModal.form.isImg")}
                      name="isImg"
                      labelId="profile-selection-label"
                      id="isImg"
                      onChange={handleIsImgChange}
                      
                      required
                    >
                       <MenuItem value={''}>&nbsp;</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                      <MenuItem value="1">Si</MenuItem>
                    </Field>
                  </FormControl>
                </div>
                <Field
                  as={TextField}
                  label={i18n.t("botQueriesModal.form.imgName")}
                  name="imgName"
                  error={touched.imagN && Boolean(errors.imgName)}
                  helperText={touched.imagN && errors.imgName}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  hidden={!isImgSelected}

                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("botModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {botId
                    ? `${i18n.t("botModal.buttons.okEdit")}`
                    : `${i18n.t("botModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default BotModal;
